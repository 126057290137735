'use client';

import clsx from 'clsx';
import { useState } from 'react';
import Link from 'next/link';
import { Logo } from '@/components/logo';
import { ThemeToggle } from './themeToggle';
import { Button } from '@/components/ui/button';
import { NavInternalLink } from '@/components/NavInternalLink';

const MobileNav = ({ className }: { className: string }) => {
  const [open, setOpen] = useState(false);
  const toggleNav = () => {
    setOpen((prev) => !prev);
  };
  return (
    <div
      className={`cursor-pointer flex justify-between items-center ${className}`}
    >
      <Link href="/">
        <Logo darkLogo={false} />
      </Link>

      <div
        className="flex items-center gap-4 pl-2 pt-2 pb-2 "
        onClick={toggleNav}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={clsx({ hidden: open }, 'w-6 h-6 text-foreground')}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={clsx(
            { hidden: !open },
            'w-6 h-6 relative text-foreground'
          )}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>

        {/* IMPORTANT::Temporary remove */}
        {/* <ThemeToggle /> */}
      </div>

      <ul
        className={clsx(
          { hidden: !open },
          'absolute py-4 flex flex-col right-10 top-16 shadow-xl border border-gray-100 rounded-2xl z-50 bg-white'
        )}
        onClick={toggleNav}
      >
        <li className="hover:bg-cardinal-red hover:text-foreground text-black">
          <NavInternalLink
            btnText="Home"
            elementToScrollTo="home"
            className="flex py-2 px-8 no-underline"
          />
        </li>
        <li className="hover:bg-cardinal-red hover:text-foreground text-black">
          <NavInternalLink
            btnText="Leistungen"
            elementToScrollTo="features"
            className="flex py-2 px-8 no-underline"
          />
        </li>
        <li className="hover:bg-cardinal-red hover:text-foreground text-black">
          <NavInternalLink
            btnText="Wie es funktioniert"
            elementToScrollTo="how-it-works"
            className="flex py-2 px-8 no-underline"
          />
        </li>
        <li className="hover:bg-cardinal-red hover:text-foreground text-black">
          <NavInternalLink
            btnText="Team"
            elementToScrollTo="team"
            className="flex py-2 px-8 no-underline"
          />
        </li>
        <li className="hover:bg-cardinal-red hover:text-foreground text-black">
          <Link href="/blog" className="flex py-2 px-8">
            Blog
          </Link>
        </li>

        {/* {user ? (
          <Link href="/dasboard" className="flex py-2 px-8">
            Dashbaord
          </Link>
        ) : (
          <Link href="/login" className="flex justify-center">
            <Button variant="default" size="default">
              Jetzt entdecken!
            </Button>
          </Link>
        )} */}
      </ul>
    </div>
  );
};

export default MobileNav;
