import React from 'react';

type LogoProps = {
  darkLogo: boolean;
};

const lightLogo = (
  <img src="/assets/logo-light.svg" alt="BlendLane.com" className="w-48" />
);
const darkLogo = (
  <img src="/assets/logo-dark.svg" alt="BlendLane.com" className="w-48" />
);
export const Logo = ({ darkLogo }: LogoProps) => {
  return (
    <div className="flex items-center">{darkLogo ? darkLogo : lightLogo}</div>
  );
};
