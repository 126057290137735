'use client';
import { useEffect, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useClientScroll } from '@/hooks/useClientScroll';

const SmoothScrollToAnchor = () => {
  const { scrollToElement } = useClientScroll();
  const pathName = usePathname();
  useEffect(() => {
    let hash = window.location.hash;
    if (hash.includes('#-to')) {
      hash = hash.replace('#-to', '');
      scrollToElement(hash, 120);
    }
  }, [pathName]);

  return null; // This component doesn't render anything
};

export default SmoothScrollToAnchor;
