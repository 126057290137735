'use client';
import React, { useState } from 'react';
import { hasCookie, setCookie } from 'cookies-next';
import { Button } from '@/components/ui/button';

const CookieConsent = (props: any) => {
  const [showConsent, setShowConsent] = useState(true);

  React.useEffect(() => {
    setShowConsent(hasCookie('localConsent'));
  }, []);

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie('localConsent', 'true', {});
  };

  if (showConsent) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-slate-700 bg-opacity-70">
      <div className="fixed bottom-0 left-0 right-0 flex items-center justify-between px-4 py-8 bg-gray-100">
        <span className="text-dark text-base mr-16">
          Diese Webseite nutzt cookies. Wenn du die Website nutzt, stimmst du
          automatisch unseren Cookie-Richtlinien zu.
        </span>
        <Button onClick={() => acceptCookie()} className="bg-pine-green">
          Akzeptieren
        </Button>
      </div>
    </div>
  );
};

export default CookieConsent;
